import React, {useState, useEffect} from 'react'
import { TrashIcon } from '@heroicons/react/solid';
import { useLocation,useNavigate } from 'react-router-dom';
import safe from '../../../assets/Safe.webp'

const Cartitem = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState(location.state?.cartItems || []);
  const [totalPrice, setTotalPrice] = useState(location.state?.totalPrice || 0);

  useEffect(() => {
    // Calculate total price whenever cartItems change
    const calculateTotalPrice = () => {
      const newTotalPrice = cartItems.reduce((acc, item) => {
        return acc + item.salePrice * item.quantity; // Update this if your data structure is different
      }, 0);
      setTotalPrice(newTotalPrice);
    };

    calculateTotalPrice();
  }, [cartItems]);

  const handleRemove = (index) => {
    // Remove the item and update cartItems state
    const updatedCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCartItems);
    
    // Update total price
    // const updatedTotalPrice = updatedCartItems.reduce((acc, item) => {
    //   return acc + item.salePrice * item.quantity;
    // }, 0);
    // setTotalPrice(updatedTotalPrice);
  };

  const handleContinue = () => {
    // Navigate to the Address component
    navigate('/address', { state: { totalPrice } });
  };

  if (cartItems.length === 0) {
    navigate("/"); // Redirect to Addcart if no cart data is available
    return null;
  }

  return (
    <>
    {cartItems.map((item, index) => (
    <div key={index} className="flex items-center border-b border-gray-200 py-4 space-x-4 max-w-4xl mx-auto px-5">
      {/* Product Image */}
      <div className="w-1/4">
        <img
          src={item.image}
          alt="Product"
          className="rounded-md w-full object-contain"
        />
      </div>
      
      {/* Product Details */}
      <div className="w-3/4 flex flex-col justify-between">
        {/* Product Title and Delete Icon */}
        <div className="flex justify-between items-start">
          <h2 className="text-sm max-sm:text-[11px] font-bold text-gray-800 overflow-hidden whitespace-nowrap text-ellipsis">
            {item.name}
          </h2>
          <TrashIcon onClick={() => handleRemove(index)} className="w-6 h-6 max-sm:w-5 max-sm:h-5  text-gray-400 cursor-pointer hover:text-gray-600" />
        </div>

        {/* Price */}
        <div className="flex items-center mt-2 space-x-2">
          <span className="text-lg font-medium text-[#353543] max-sm:text-sm">₹{item.salePrice}</span>
          <span className="text-sm text-[#b3b3b5] line-through font-semibold">₹{item.regularPrice}</span>
        </div>

        {/* Size and Quantity */}
        <div className="flex justify-between items-center mt-2">
          <span className="text-sm text-gray-600">Size: {item.size}</span>
          <span className="text-sm text-gray-600">Qty: {item.quantity}</span>
        </div>
      </div>
    </div>
    ))}

    <div className='px-4'>
    <div className="flex justify-between text-sm py-2">
        <h3 className='font-semibold'>Shipping:</h3>
        <p className='font-semibold'>FREE</p>
      </div>
      <div className="flex justify-between text-sm pb-2">
        <h3 className='underline'>Total Product Price:</h3>
        <p className='underline'>₹{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(totalPrice.toFixed(2))}</p>
      </div>
      <hr className="border-t border-dashed border-black" />
      <div className="flex justify-between text-sm py-4">
        <h3 className='font-semibold'>Order Total</h3>
        <p className='font-semibold'>₹{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(totalPrice.toFixed(2))}</p>
      </div>
    </div>
    <hr className='h-1 bg-slate-200'/>

    <div className='m-3 mb-16'>
      <img src={safe} alt="" />
    </div>

    <div className='fixed bottom-0 w-full bg-white flex py-2 shadow-[0_-2px_10px_rgba(0,0,0,0.1)]'>
      <div className='w-1/2 ps-5'>
        <h1 className='font-semibold'>₹{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(totalPrice.toFixed(2))}</h1>
        <p className='font-semibold text-xs text-[#9F2089] max-sm:text-[10px]'>VIEW PRICE DETAILS</p>
      </div>
      <div onClick={handleContinue} className='w-1/2 text-center bg-[#9F2089] hover:bg-fuchsia-700  text-white font-semibold text-base rounded-lg m-auto p-2 cursor-pointer me-3'>Continue</div>
    </div>
    </>
  )
}

export default Cartitem