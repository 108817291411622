import React, { useState } from "react";
import Firstcart from "../Firstcart/Firstcart";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";

const Address = () => {
  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
    "Ladakh",
    "Jammu and Kashmir",
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const [formError, setFormError] = useState("");

  const handlePayments = (event) => {
    event.preventDefault();
    const form = document.getElementById("addressForm");

    const totalPrice = location.state?.totalPrice || 0;

    if (form.checkValidity()) {
      navigate("/payments", { state: { totalPrice } });
    } else {
      setFormError("Please fill out all the required fields correctly.");
    }
  };

  return (
    <>
      <Firstcart title="ADD DELIVERY ADDRESS" currentStep={2} />

      <div className="max-w-xl mx-auto p-4 bg-white  rounded-lg">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="text-blue-500 mr-2"
          />
          <span className="text-gray-700">Address</span>
        </h2>

        <form className="space-y-4" id="addressForm" onSubmit={handlePayments}>
          <div>
            <label
              className="block text-sm font-medium mb-1 text-[#868686]"
              htmlFor="fullName"
            >
              Full Name
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName" 
              className="w-full p-2 border border-gray-300 rounded-xl focus:outline-none focus:border-blue-500"
            />
          </div>

          <div>
            <label
              className="block text-sm font-medium mb-1 text-[#868686]"
              htmlFor="mobileNumber"
            >
              Mobile number
            </label>
            <input
              type="tel"
              id="mobileNumber"
              name="mobileNumber" 
              className="w-full p-2 border border-gray-300 rounded-xl focus:outline-none focus:border-blue-500"
            />
          </div>

          <div>
            <label
              className="block text-sm font-medium mb-1 text-[#868686]"
              htmlFor="pincode"
            >
              Pincode
            </label>
            <input
              type="text"
              id="pincode"
              name="pincode"
              className="w-full p-2 border border-gray-300 rounded-xl focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label
                className="block text-sm font-medium mb-1 text-[#868686]"
                htmlFor="city"
              >
                City
              </label>
              <input
                type="text"
                id="city"
                name="city" 
                className="w-full p-2 border border-gray-300 rounded-xl focus:outline-none focus:border-blue-500"
              />
            </div>

            <div className="sm:max-w-sm md:max-w-md lg:max-w-lg mx-auto">
              <label
                className="block text-sm font-medium mb-1 text-[#868686]"
                htmlFor="state"
              >
                State
              </label>
              <select
                id="state"
                name="state" 
                className="w-full p-2 border border-gray-300 rounded-xl focus:outline-none focus:border-blue-500"
              >
                <option value="">Select state</option>
                {states.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label
              className="block text-sm font-medium mb-1 text-[#868686]"
              htmlFor="houseNo"
            >
              House No., Building Name
            </label>
            <input
              type="text"
              id="houseNo"
              name="houseNo" 
              className="w-full p-2 border border-gray-300 rounded-xl focus:outline-none focus:border-blue-500"
            />
          </div>

          <div>
            <label
              className="block text-sm font-medium mb-1 text-[#868686]"
              htmlFor="roadName"
            >
              Road name, Area, Colony
            </label>
            <input
              type="text"
              id="roadName"
              name="roadName"
              className="w-full p-2 border border-gray-300 rounded-xl focus:outline-none focus:border-blue-500"
            />
          </div>
        </form>

        {formError && (
          <div className="text-red-500 text-sm mt-2">{formError}</div>
        )}
      </div>

      <hr className="mt-3 h-0 bg-slate-300 mb-20" />

      <div className="bg-white px-2 pb-4 ">
        <button
          type="submit"
          onClick={handlePayments}
          className="fixed mb-3 bottom-0 w-[98%] text-center bg-[#9F2089] hover:bg-fuchsia-700 py-3 text-white rounded-md font-semibold max-sm:w-[95%]"
        >
          Save Address and Continue
        </button>
      </div>
    </>
  );
};

export default Address;
