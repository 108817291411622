import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home';
import Details from './Components/Details';
import Cart from './Components/Cart';
import Addcart from './Components/Addcart/Addcart';
import Address from './Components/Cart/Address/Address';
import { CartProvider } from './Components/CartContext';
import Payments from './Components/Cart/Payments/Payments';

function App() {
  return (
    <>
    <CartProvider>
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/details" element={<Details />} />
        <Route path="/cart" element={<Cart />} />
        <Route path='/address' element={<Address />} />
        <Route path='/payments' element={<Payments />} />
        <Route path="/addcart" element={<Addcart />} />
      </Routes>
    </BrowserRouter>
    </CartProvider>
    
    </>
  );
}

export default App;
