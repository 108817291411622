import React from "react";
// import './Navbar.css'
import Logo from "../../assets/meeshoLogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faShoppingCart,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useCart } from "../CartContext";

const Navbar = () => {
  const navigate = useNavigate();
  const { getCartItems } = useCart(); // Access the cart context
  const cartItems = getCartItems(); // Get the cart items
  const cartCount = cartItems.length;

  const heandleLogo = () => {
    navigate("/");
  };
  return (
    <>
      <div className="flex justify-between m-4">
        <div className="flex space-x-3">
          <svg
            className="w-6 h-6 text-gray-700 mt-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          <img
            onClick={heandleLogo}
            className="cursor-pointer w-[150px] max-sm:w-[90px]"
            src={Logo}
            alt=""
          />
        </div>
        <div className="flex gap-3 mt-2 relative">
          <a href="/">
            <FontAwesomeIcon
              icon={faHeart}
              className="text-xl text-[#ff2f36]"
            />
          </a>
          <a href="/cart" className="relative">
            <FontAwesomeIcon
              icon={faShoppingCart}
              className="text-xl text-[#f038bc]"
            />
            {cartCount > 0 && (
              <span className="absolute top-[-7px] right-[-10px] bg-[#f5bce4] text-white text-xs  rounded-full px-1">
                {cartCount}
              </span>
            )}
          </a>
        </div>
      </div>

      <div>
        <div className="w-full px-4 max-sm:px-2 sm:px-6 md:px-8 lg:px-12">
          <div className="flex items-center bg-gray-100 border border-gray-300 rounded-lg px-4 max-sm:px-2 py-1 w-full max-w-4xl mx-auto">
            {/* Search Icon */}
            <FontAwesomeIcon
              icon={faSearch}
              className="text-gray-400 text-xl"
            />

            {/* Input Field */}
            <input
              type="text"
              placeholder="Search for Sarees, Kurtis, Cosmetics, etc."
              className="flex-grow bg-transparent outline-none text-gray-500 text-base sm:text-lg placeholder:text-gray-400 placeholder:text-sm px-4 max-sm:px-2 truncate max-w-xs overflow-hidden"
            />
          </div>
        </div>
        <hr className="mt-3" />
      </div>
    </>
  );
};

export default Navbar;
