// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mon{
    background-color: rgb(231, 238, 255);
    padding: 10px 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Cart/Payments/Payments.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,kBAAkB;AACtB","sourcesContent":[".mon{\r\n    background-color: rgb(231, 238, 255);\r\n    padding: 10px 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
