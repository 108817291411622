import React from 'react'
import Firstcart from './Firstcart/Firstcart'
import Cartitem from './Cartitem/Cartitem'
import { Outlet } from 'react-router-dom';

const Cart = () => {

  return (
    <>
    <Firstcart/>
    <Cartitem />
    <Outlet />
    </>
  )
}

export default Cart