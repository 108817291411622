import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'; 
import { useNavigate } from 'react-router-dom';

const Firstcart = ({title = "CART",currentStep = 1}) => {
    const navigate = useNavigate();


    const handleReturn = () => {
      console.log("Navigating to Addcart");
        navigate('/addcart')
    }

    const handleStepClick = (stepNumber) => {
      if (stepNumber === 3) {
        navigate('/address'); // Navigate to Address when step 3 is clicked
      }
    };

    const getStepStyle = (stepNumber) => {
      if (stepNumber < currentStep) return "border-blue-500 text-blue-500"; // Completed step
      if (stepNumber === currentStep) return "border-blue-500 text-white bg-blue-500"; // Current step
      return "border-gray-300 text-gray-300"; // Inactive step
    };
  
    // Function to determine step label color
    const getStepLabelStyle = (stepNumber) => {
      if (stepNumber <= currentStep) return "text-black font-medium";
      return "text-gray-400";
    };


  return (
    <>
    <div className="flex items-center mt-2 ms-3 cursor-pointer">
       <FontAwesomeIcon
           icon={faAngleLeft}
           className="text-2xl text-gray-500 mr-2 cursor-pointer"
        />  
        <h1 className="text-sm font-semibold" onClick={handleReturn}>{title}</h1>
    </div>
    <hr className='my-2'/>

    <div className="w-full flex flex-col items-center px-4 ">
      {/* Stepper */}
      <div className="w-full max-w-xl flex items-center justify-between relative">
        
        {/* Line between Step 1 and Step2 */}
        <div className="absolute top-4 max-sm:top-2 max-sm:left-[9%] max-sm:right-[75%] left-[7%] right-[73%] border-t-2 border-gray-300 z-0" />

        {/* Line between Step 2 and Step 3 */}
        <div className="absolute top-4 max-sm:top-2 max-sm:left-[33%] max-sm:right-[45%] left-[35%] right-[41%] border-t-2 border-gray-300 z-0" />

        {/* Line between Step 3 and Step 4 */}
        <div className="absolute top-4 max-sm:top-2 max-sm:left-[62%] max-sm:right-[16%] left-[66%] right-[10%] border-t-2 border-gray-300 z-0" />

        {/* Step 1 */}
        <div className="flex flex-col items-center z-10">
          <div className={`flex max-sm:text-[10px] items-center justify-center w-8 h-8 max-sm:w-5 max-sm:h-5 rounded-full border-2 ${getStepStyle(1)}`}>
            1
          </div>
          <span className={`mt-2 ${getStepLabelStyle(1)} font-medium max-sm:text-sm`}>Cart</span>
        </div>

        {/* Step 2 */}
        <div className="flex flex-col items-center z-10">
          <div className={`flex max-sm:text-[10px] items-center justify-center w-8 h-8 max-sm:w-5 max-sm:h-5 rounded-full border-2 ${getStepStyle(2)}`}>
            2
          </div>
          <span className={`mt-2 ${getStepLabelStyle(2)}  max-sm:text-sm`}>Address</span>
        </div>

        {/* Step 3 */}
        <div className="flex flex-col items-center z-10 cursor-pointer" onClick={() => handleStepClick(3)}>
          <div className={`flex max-sm:text-[10px] items-center justify-center w-8 h-8 max-sm:w-5 max-sm:h-5 rounded-full border-2 ${getStepStyle(3)}`}>
            3
          </div>
          <span className={`mt-2 ${getStepLabelStyle(3)} max-sm:text-sm`}>Payment</span>
        </div>

        {/* Step 4 */}
        <div className="flex max-sm:text-[10px] flex-col items-center z-10">
          <div className={`flex items-center justify-center w-8 h-8 max-sm:w-5 max-sm:h-5 rounded-full border-2 ${getStepStyle(4)}`}>
            4
          </div>
          <span className={`mt-2 ${getStepLabelStyle(4)} max-sm:text-sm`}>Summary</span>
        </div>
      </div>
    </div>

    <hr className='mt-3 h-1 bg-[#fff6f6]'/>
    </>
  )
}

export default Firstcart