import React from 'react'
import Navbartwo from './Navbartwo/Navbartwo'
import Sliderone from './Sliderone/Sliderone'
import Products from './Products/Products'
import Footone from './Footone/Footone'

const Home = () => {
  return (
    <>
    <Navbartwo />
    <Sliderone />
    <Products />     
    <Footone />
    </>
  )
}

export default Home