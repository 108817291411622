import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Mees from "../../../assets/download.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Products = () => {
  const navigate = useNavigate();  
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch("/csvjson.json"); // Correct path to your JSON
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Fetched data:", data); // Log fetched data
        setProducts(data); // Directly set the fetched data
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

const handleProductClick = (id) => {
  const selectedProduct = products.find((product) => product.ID === id);
    navigate(`/details`, {
      state: {
          ...selectedProduct,
          similarImages: selectedProduct.similarImages || [], // Ensure similarImages is included
      },
   });
};



  return (
    <>
      <div>
        <h2 className="text-4xl font-semibold m-6 text-center max-sm:text-2xl max-sm:ms-4">
          Products For You
        </h2>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-1 p-1">
        {products.map((product) => (
          <div
            key={product.ID}
            className={`border rounded-lg p-2 shadow-sm hover:shadow-lg transition-shadow duration-2000`}
            onClick={() => handleProductClick(product.ID)}
            style={{ cursor: "pointer" }}
          >
            <div className="mb-4">
              <img
                src={product.Images[0]}
                alt={product.Name}
                className="w-full h-40 max-sm:h-32 object-contain rounded-lg"
              />
            </div>
            <div className="text-left">
              <h2 className="text-lg sm:text-base md:text-lg font-medium mb-1 max-sm:text-[10px] text-[#8B8BA3]">
                {product.Name}
              </h2>
              <div className="flex gap-1 max-sm:text-xs max-sm:flex-wrap">
                <div className="text-sm sm:text-lg font-semibold text-gray-800">
                  ₹{product["Sale price"]}
                </div>
                <div className="text-gray-500 mt-[5px] line-through max-sm:text-[10px]">
                  ₹{product["Regular price"]}
                </div>
              </div>
              <div className="text-xs sm:text-sm text-gray-500 bg-[#fff9f9] rounded-lg mt-2">
                Free Delivery
              </div>
              <div className="flex items-center justify-between mt-4">
                <div className="flex items-center space-x-1">
                  <span className="text-xs bg-[#038D63] text-white px-2 py-1 rounded-xl flex items-center">
                    4.0
                    <FontAwesomeIcon
                      icon={faStar}
                      className="ml-1 text-white text-xs"
                    />
                  </span>
                  <span className="text-xs text-gray-600">(0)</span>
                </div>
                <div>
                  <img src={Mees} alt="Mees Icon" className="h-6 w-12 object-contain" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Products;
