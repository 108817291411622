import React from 'react';
import Mes from "../../../assets/mes logo.svg";
import Tshirt from "../../../assets/t-shirt logo.svg";
import Box from "../../../assets/box.svg";
import Quation from "../../../assets/quation mark.svg";
import Bol from "../../../assets/bool.svg";

const Footone = () => {
  return (
    <>
      <div className="fixed bottom-0 w-full flex justify-evenly bg-white py-2 shadow-[0_-2px_10px_rgba(0,0,0,0.1)] z-50">
        <div className="flex flex-col items-center">
          <img className="ms-1" src={Mes} alt="Home" />
          <p className="text-sm max-sm:text-[12px]">Home</p>
        </div>
        <div className="flex flex-col items-center">
          <img className="ms-2" src={Tshirt} alt="Categories" />
          <p className="text-sm max-sm:text-[12px]">Categories</p>
        </div>
        <div className="flex flex-col items-center">
          <img className="ms-1" src={Box} alt="My Orders" />
          <p className="text-sm max-sm:text-[12px]">My Orders</p>
        </div>
        <div className="flex flex-col items-center">
          <img src={Quation} alt="Help" />
          <p className="text-sm max-sm:text-[12px]">Help</p>
        </div>
        <div className="flex flex-col items-center">
          <img className="ms-1" src={Bol} alt="Account" />
          <p className="text-sm max-sm:text-[12px]">Account</p>
        </div>
      </div>
    </>
  );
};

export default Footone;
