import React, { useState } from "react";
import Firstcart from "../Firstcart/Firstcart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import gpay from "../../../assets/gpay_icon.svg";
import phonepay from "../../../assets/phonepe.svg";
import paytm from "../../../assets/paytm_icon.svg";
import wpay from "../../../assets/whatspp_pay.svg";
import "./Payments.css"; // Ensure you have your CSS imported
import { useLocation } from "react-router-dom";
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';



const Payments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const totalPrice = location.state?.totalPrice || 0;

  const [selectedOption, setSelectedOption] = useState("");

  const paymentMethods = [
    { id: "GPay", name: "GPay", logo: gpay },
    { id: "PhonePe", name: "PhonePe", logo: phonepay },
    { id: "Paytm", name: "Paytm", logo: paytm },
    { id: "WhatsAppPay", name: "WhatsApp Pay", logo: wpay },
  ];
  const handleTitleClick = () => {
    navigate('/address'); // Navigate to the Address component
  };

  const handlePayNow = () => {
    let url = "";

    // Use switch to set the URL based on selected payment method
    switch (selectedOption) {
      case "GPay":
        url = `gpay://upi/pay?pa=mswipe.2500121024129777@kotak&am=${totalPrice}&pn=shopsiteforyou&tr=cWlb5sIV0YVgY&mc=8931&orgid=000000&mode=01&cu=INR&tn=shopsiteforyou+OrderId:1133`;
        break;
      case "PhonePe":
        url = `phonepe://pay?pa=mswipe.2500121024129777@kotak&am=${totalPrice}&pn=shopsiteforyou&tr=cWlb5sIV0YVgY&mc=8931&orgid=000000&mode=01&cu=INR&tn=shopsiteforyou+OrderId:1133`;
        break;
      case "Paytm":
        url = `paytmmp://pay?pa=mswipe.2500121024129777@kotak&am=${totalPrice}&pn=shopsiteforyou&tr=cWlb5sIV0YVgY&mc=8931&orgid=000000&mode=01&cu=INR&tn=shopsiteforyou+OrderId:1133`;
        break;
      default:
        alert("Please select a payment method.");
        return;
    }

    // Redirect to the selected UPI URL
    window.location.href = url;
  };

  return (
    <>
      <Firstcart title="PAYMENTS" currentStep={3} onTitleClick={handleTitleClick}  />

      <div>
        <div className="flex justify-between my-4 mx-3">
          <h1 className="text-xl text-black font-bold max-sm:text-[15px]">
            Select Payment Method
          </h1>
          <div className="flex gap-1">
            <FontAwesomeIcon
              icon={faShieldAlt}
              className="text-lg text-blue-300 mt-2"
            />
            <p className="text-[11px] text-[#c2c2c5] font-semibold">
              100% SAFE <br /> PAYMENTS
            </p>
          </div>
        </div>

        <div className="w-full max-w-md mx-auto p-4 bg-white shadow-md rounded-md">
          <h3 className="text-lg font-semibold mb-4">PAY ONLINE</h3>
          <div className="border border-gray-300 rounded-md p-4">
            <div className="flex items-center justify-between mb-4">
              <span className="text-sm font-medium">
                UPI (GPay/PhonePe/Paytm)
              </span>
              <div className="flex items-center">
        <ChevronDownIcon className="h-5 w-5 text-gray-500" /> {/* Change size and color as needed */}
      </div>
            </div>
            {paymentMethods.map((method) => (
              <div
                key={method.id}
                className="flex items-center justify-between py-5 border-t border-gray-200 first:border-t-0 cursor-pointer"
                onClick={() => setSelectedOption(method.id)}
              >
                <div className="flex items-center space-x-2">
                  <input
                    type="radio"
                    id={method.id}
                    name="paymentMethod"
                    value={method.id}
                    checked={selectedOption === method.id}
                    onChange={() => setSelectedOption(method.id)}
                    className="form-radio h-5 w-5 cursor-pointer accent-blue-500"
                  />
                  <label htmlFor={method.id} className="text-sm font-medium">
                    {method.name}
                  </label>
                </div>
                <img src={method.logo} alt={method.name} className="h-6" />
              </div>
            ))}
          </div>
        </div>

        <div className="font-semibold mt-3 pb-20">
          <div className="flex justify-between text-sm py-2 px-2">
            <h1>Shipping</h1>
            <p>FREE</p>
          </div>
          <div className="flex justify-between text-sm py-2 px-2">
            <h1>Total Product Price:</h1>
            <p>₹{totalPrice.toFixed(2)}</p>
          </div>
          <hr className="my-2 border-t border-dashed border-black" />
          <div className="flex justify-between text-sm py-2 px-2 mb-3">
            <h1>Order Total:</h1>
            <p>₹{totalPrice.toFixed(2)}</p>
          </div>
          <hr className="h-1 bg-gray-200" />
        </div>

        <div className="fixed bottom-0 w-full bg-white flex py-2 shadow-[0_-2px_10px_rgba(0,0,0,0.1)]">
          <div className="w-1/2 ps-2">
            <h1 className="font-semibold">
            ₹{totalPrice.toFixed(2)}
            </h1>
            <p className="font-semibold text-xs text-[#9F2089] max-sm:text-[10px]">
              VIEW PRICE DETAILS
            </p>
          </div>
          <div onClick={handlePayNow} className="w-1/2 text-center bg-[#9F2089] hover:bg-fuchsia-700 me-2  text-white font-semibold text-base rounded-lg m-auto p-2 cursor-pointer">
            PayNow
          </div>
        </div>
      </div>
    </>
  );
};

export default Payments;
