// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for the slider */
.mySwiper {
    --swiper-pagination-color: #6b46c1; /* Example color for the pagination */
}

.mySwiper img {
    height: auto;
    object-fit: cover; /* Ensures the image fits properly */
}

/* ScrollingText.css */
/* Set up container */
.marquee {
    overflow: hidden;
    position: relative;
    background-color: #970e71; /* Background color */
    color: #ffffff; /* Text color */
    padding: 0.5rem; /* Padding */
  }
  
  /* Set up inner content */
  .marquee__inner {
    display: flex;
    white-space: nowrap;
    width: calc(100% * 2);
    animation: marquee 15s linear infinite;
  }
  
  /* Define scrolling animation */
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* Optional: Add spacing between items */
  .marquee__inner span {
    margin-right: 1rem; /* Adjust spacing as needed */
  }

  .time{
    border: 1px solid rgb(250, 162, 0);
    background-color: rgb(255,232,205);
    display: flex;
    border-radius: 5px;
}
.time p{
    color: #970e71;
    font-size: 12px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Home/Sliderone/Slider.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;IACI,kCAAkC,EAAE,qCAAqC;AAC7E;;AAEA;IACI,YAAY;IACZ,iBAAiB,EAAE,oCAAoC;AAC3D;;AAEA,sBAAsB;AACtB,qBAAqB;AACrB;IACI,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB,EAAE,qBAAqB;IAChD,cAAc,EAAE,eAAe;IAC/B,eAAe,EAAE,YAAY;EAC/B;;EAEA,yBAAyB;EACzB;IACE,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,sCAAsC;EACxC;;EAEA,+BAA+B;EAC/B;IACE;MACE,2BAA2B;IAC7B;IACA;MACE,4BAA4B;IAC9B;EACF;;EAEA,wCAAwC;EACxC;IACE,kBAAkB,EAAE,6BAA6B;EACnD;;EAEA;IACE,kCAAkC;IAClC,kCAAkC;IAClC,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,eAAe;EACjB","sourcesContent":["/* Custom styles for the slider */\r\n.mySwiper {\r\n    --swiper-pagination-color: #6b46c1; /* Example color for the pagination */\r\n}\r\n\r\n.mySwiper img {\r\n    height: auto;\r\n    object-fit: cover; /* Ensures the image fits properly */\r\n}\r\n\r\n/* ScrollingText.css */\r\n/* Set up container */\r\n.marquee {\r\n    overflow: hidden;\r\n    position: relative;\r\n    background-color: #970e71; /* Background color */\r\n    color: #ffffff; /* Text color */\r\n    padding: 0.5rem; /* Padding */\r\n  }\r\n  \r\n  /* Set up inner content */\r\n  .marquee__inner {\r\n    display: flex;\r\n    white-space: nowrap;\r\n    width: calc(100% * 2);\r\n    animation: marquee 15s linear infinite;\r\n  }\r\n  \r\n  /* Define scrolling animation */\r\n  @keyframes marquee {\r\n    0% {\r\n      transform: translateX(100%);\r\n    }\r\n    100% {\r\n      transform: translateX(-100%);\r\n    }\r\n  }\r\n  \r\n  /* Optional: Add spacing between items */\r\n  .marquee__inner span {\r\n    margin-right: 1rem; /* Adjust spacing as needed */\r\n  }\r\n\r\n  .time{\r\n    border: 1px solid rgb(250, 162, 0);\r\n    background-color: rgb(255,232,205);\r\n    display: flex;\r\n    border-radius: 5px;\r\n}\r\n.time p{\r\n    color: #970e71;\r\n    font-size: 12px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
